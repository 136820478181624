/*
 * @Description: 
 * @Author: 张宏军
 * @Date: 2024-10-29 21:59:48
 * @LastEditTime: 2024-11-08 20:28:52
 * @LastEditors: 张宏军
 */


import request from '../util/request'
import request1 from '../util/request1'
//接口文件呢，post请求参数名为data，get请求参数名为params

// //服务中心列表
// export function getStationList(data) {
//     return request({
//         url: '/Home/GetStationList2',
//         method: 'POST',
//         data
//     })
// }   

// //服务中心省市
// export function getPCAData(data) {
//     return request({
//         url: '/Home/GetPCAData',
//         method: 'POST',
//         data
//     })
// } 

// //服务中心类型
// export function getMenuData(data) {
//     return request({
//         url: '/Home/GetMenuData',
//         method: 'POST',
//         data
//     })
// } 

// //门店查询省市
// export function getPCList(data) {
//     return request1({
//         url: '/ServiceStation/GetPCList',
//         method: 'POST',
//         data
//     })
// } 

// //门店查询列表
// export function getStoreList(data) {
//     return request1({
//         url: '/ServiceStation/GetStoreList',
//         method: 'POST',
//         data
//     })
// } 

// //获取搜索词
// export function getKeyWordList(data) {
//     return request1({
//         url: '/ServiceStation/GetKeyWordList',
//         method: 'POST',
//         data
//     })
// } 

// //获取搜索列表
// export function getQuickSearchList(data) {
//     return request1({
//         url: '/ServiceStation/GetQuickSearchList',
//         method: 'POST',
//         data
//     })
// } 

export function getQuickSearchList(params) {
    return request({
        url: '/Home/Index/indexss',
        method: 'get',
        params
    })
} 

export function areafind(params) {
    return request({
        url: '/Home/Index/areafind',
        method: 'get',
        params
    })
} 

export function areasearch(params) {
    return request({
        url: '/Home/Index/areasearch',
        method: 'get',
        params
    })
} 