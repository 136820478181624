<!--
 * @Description: 
 * @Author: 张宏军
 * @Date: 2024-10-29 21:59:49
 * @LastEditTime: 2024-11-14 19:44:28
 * @LastEditors: 张宏军
-->
<template>
  <div class="home" v-loading.fullscreen.lock="fullscreenLoading" @touchstart="preventSwipeBack">
    <div class="map" id="map"></div>
    <div class="positioning" @click="positioning"></div>
    <div class="butBox" >
      <el-button :type="language === 'zh' ? 'primary' : ''" @click="addZh">中文</el-button>
      <el-button :type="language === 'mo' ? 'primary' : ''" @click="addMongolian">蒙古文</el-button>
    </div>
    <div class="logo" @click="refPage"></div>
    <div v-if="language === 'zh'" class="searchBox">
      <div class="inputBox">
        <el-popover
          placement="bottom"
          :width="pc ? '600px' : (100 / 750) * 630 + 'vw'"
          :popper-class="pc ? 'l50' : ''"
          :visible="inputVisible"
        >
          <template #reference>
            <div class="input" @click="inputVisible=true">
              <input ref="input" type="text" :placeholder="pc?'可用汉语名称、汉语音标、蒙语名称、蒙语音标、内容、经度、维度进行模糊检索':'请输入搜索内容'" class="inputItem" v-model="searchVal">
              <div class="inputBtn" @click="areasearch(1)">搜索</div>
            </div>
            
          </template>
          <div class="searchListBox">
            <div class="tabBox">
              <el-button :type="placeNameType === '1' ? 'primary' : ''" link @click="placeNameTypeChange('1')">考证地名</el-button> <span class="line"></span>
              <el-button :type="placeNameType === '2' ? 'primary' : ''" link @click="placeNameTypeChange('2')">一般地名</el-button> <span class="line"></span>
              <el-button :type="placeNameType === '3' ? 'primary' : ''" link @click="placeNameTypeChange('3')">文化地名</el-button>
            </div>
            <ul class="searchList" style="overflow: auto">
              <li v-for="item in searchList" :key="item.id" class="searchListItem zh" @click="searchPositioning(item)">{{ item.name +' '+ item.nameb }} <span class="mo">{{ item.mnname }} </span>{{ item.mnnameb }}</li>
            </ul>
            <div class="paginationBox" v-if="searchList.length">
              <el-pagination layout="prev, pager, next" :page-size="10" v-model:current-page="page" :total="total" @current-change="paging" />
              <span class="total"> {{total}}</span>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div v-if="language === 'mo'" class="searchBox searchBox1">
      <div class="inputBox">
        <el-popover
          placement="right"
          :visible="inputVisible"
          :width="pc ? (100 / 750) * 400 + 'vw' : (100 / 750) * 550 + 'vw'"
        >
          <template #reference>
            <div class="input" @click="inputVisible=true">
              <input ref="input" type="text" placeholder="ᠬᠠᠢᠬᠤ ᠠᠭᠤᠯᠭ᠎ᠠ ᠪᠠᠨ ᠡᠨᠳᠡ ᠪᠢᠴᠢᠭᠡᠷᠠᠢ" class="inputItem" v-model="searchVal">
              <div class="inputBtn mo" @click="areasearch(1)">ᠬᠠᠢᠬᠤ</div>
            </div>
            
          </template>
          <div tabindex="0" class="searchListBox searchListBox1">
            <div class="tabBox" tabindex="0">
              <el-button class="aqt-lang-mn" :type="placeNameType === '1' ? 'primary' : ''" link @click="placeNameTypeChange('1')">ᠰᠢᠯᠭᠠᠨ ᠨᠤᠲᠠᠯᠠᠭᠰᠠᠨ ᠤ ᠳᠠᠷᠠᠭᠠᠬᠢ ᠭᠠᠵᠠᠷ ᠤᠨ ᠨᠡᠷ᠎ᠡ</el-button> <span class="line"></span>
              <el-button class="aqt-lang-mn" :type="placeNameType === '2' ? 'primary' : ''" link @click="placeNameTypeChange('2')">ᠡᠩ ᠦᠨ ᠭᠠᠵᠠᠷ ᠤᠨ ᠨᠡᠷ᠎ᠡ</el-button> <span class="line"></span>
              <el-button class="aqt-lang-mn" :type="placeNameType === '3' ? 'primary' : ''" link @click="placeNameTypeChange('3')">ᠰᠣᠶᠣᠯ ᠤᠨ ᠭᠠᠵᠠᠷ ᠤᠨ ᠨᠡᠷ᠎ᠡ</el-button>
            </div>
            <ul class="searchList" style="overflow: auto">
              <li v-for="item in searchList" :key="item.id" class="searchListItem aqt-lang-mn" @click="searchPositioning(item)">{{ item.mnname }} <span class="zh"> {{ item.mnnameb }} {{ item.name +' '+ item.nameb }}</span> </li>
            </ul>
            <div class="paginationBox" v-if="searchList.length">
              <el-pagination layout="prev, pager, next" v-model:current-page="page" :page-size="10" :total="total" @current-change="paging" />
              <span class="total"> {{total}}</span>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible">
      <div class="line"></div>
      <div v-if="dialogTableVisible" class="mo content" v-html="contentData"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getQuickSearchList, areafind, areasearch } from '@/api'
import mapData from "@/util/data";
export default {
  name: "map",
  components: {},
  data() {
    //坐标系转换  @author qiyulin  @date 2022.10.17
    return {
      ak: "59b8fcdcc0c6189a91c7f42d7c3c17c9", // 百度的地图密钥
      control: null,
      map: null,
      pc: true,
      fullscreenLoading: false,
      lay_zh: null,
      img_mongolian: null,
      searchVal: "",
      activeName: "first",
      activeName1: "content",
      language: "zh",
      dialogTableVisible: false,
      quickSearchList:[],
      contentData: '',
      contenttit: '',
      page: 1,
      total: 0,
      placeNameType: '',
      searchList: [],
      serachVisible: false,
      inputVisible: false
    };
  },
  created() {
    if (window.env === "pc") {
      this.pc = true;
    } else {
      this.pc = false;
    }
    // this.getQuickSearchList()
  },
  async mounted() {
    console.log(mapData);
    await this.init();
    document.addEventListener('click', this.popoverShowFun);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //引入百度地图
    init() {
      let _this = this;
      //console.log("初始化地图脚本...");
      // const AK = "59b8fcdcc0c6189a91c7f42d7c3c17c9";
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://api.tianditu.gov.cn/api?v=4.0&tk=" + _this.ak; // 替换为你的key
      document.head.appendChild(script);

      // 等待脚本加载完成
      script.onload = function () {
        //中文注记
        var img_zh =
          "http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=59b8fcdcc0c6189a91c7f42d7c3c17c9";
        //创建自定义图层对象
        _this.lay_zh = new T.TileLayer(img_zh, { minZoom: 1, maxZoom: 18 });
        _this.lay_zh.setZIndex(12);

        //蒙古文注记
        var img_mongolian =
          "http://t0.tianditu.gov.cn/mva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=mva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=59b8fcdcc0c6189a91c7f42d7c3c17c9";
        //创建自定义图层对象
        _this.lay_mongolian = new T.TileLayer(img_mongolian, {
          minZoom: 1,
          maxZoom: 18,
        });
        _this.lay_mongolian.setZIndex(12);

        var imageURL =
          "http://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=59b8fcdcc0c6189a91c7f42d7c3c17c9";
        //创建自定义图层对象
        var lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
        // lay.setZIndex(1)
        var imageURL1 =
          "http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=59b8fcdcc0c6189a91c7f42d7c3c17c9";
        //创建自定义图层对象
        var lay1 = new T.TileLayer(imageURL1, { minZoom: 1, maxZoom: 18 });
        var config = { layers: [lay] };
        // 初始化地图
        _this.map = new T.Map("map", config);
        // var lo = new T.Geolocation();
        // var geocoder
        //    var fn = function (e) {
        //         if (this.getStatus() == 0){
        //             //alert("获取定位坐标："+e.lnglat.lat + "," + e.lnglat.lng)
        //             //详细地址，逆地理编码
        //             geocoder=new T.Geocoder();
        //             geocoder.getLocation(e.lnglat, searchResult);
                 
        //         }
        //         if(this.getStatus() == 1){
        //             //alert("获取定位坐标3："+e.lnglat.lat + "," + e.lnglat.lng)
        
        //             //详细地址，逆地理编码
        //             geocoder=new T.Geocoder();
        //             geocoder.getLocation(e.lnglat, searchResult);
                  
        //         }
        //     }
        //     lo.getCurrentPosition(fn);
            
        //     //详细地址的回调函数
        //     function searchResult(result){
        //         if(result.getStatus()==0){
        //             self.address = result.getAddress();
        //             console.log('result:::::::',result)
        //             //alert(result.getAddress());
        //             alert("定位3成功信息：", result.getAddress());
        //             // _this.map.centerAndZoom(new T.LngLat(result.location.lon, result.location.lat), 12);
        //         }else{
        //            	alert(result.getMsg());
        //             console.log(result.getMsg());
        //         }
        //     }
        //     return
        // _this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12);

        _this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            console.log("经度：" + lng + "，纬度：" + lat);
            _this.map.centerAndZoom(new T.LngLat(lng, lat), 12);
          }, function (err) {
            _this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12);
            console.log("获取手机位置失败，请检查gps权限是否拒绝了")
            return null;
        },{timeout:6000});
        } else {
          _this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12);
          console.log("浏览器不支持地理定位");
        }
        //创建缩放平移控件对象
        _this.control = new T.Control.Zoom();
        //添加缩放平移控件
        _this.map.addControl(_this.control);
        _this.control.setPosition(T_ANCHOR_BOTTOM_RIGHT);
        //创建比例尺控件对象
        var scale = new T.Control.Scale();
        //添加比例尺控件
        _this.map.addControl(scale);

        //创建对象
        var ctrl = new T.Control.MapType([
          {
            title: "地图",
            icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png",
            layer: lay,
          },
          {
            title: "卫星",
            icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
            layer: lay1,
          },
        ]);
        //添加控件
        _this.map.addControl(ctrl);
        ctrl.setPosition(T_ANCHOR_BOTTOM_RIGHT);
        // ctrl.setPosition(lay1)
        _this.addZh();
        console.log(_this.map, 1111);

        _this.map.addEventListener("moveend", (e) => {
          _this.annotation();

        });
      };
    },
    positioning() {
      // 创建位置服务实例
      let _this = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          console.log("经度：" + lng + "，纬度：" + lat);
          _this.map.centerAndZoom(new T.LngLat(lng, lat), 12);
        }, function (err) {
            _this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12);
            console.log("获取手机位置失败，请检查gps权限是否拒绝了")
            return null;
        },{timeout:6000});
      } else {
        _this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12);
        console.log("浏览器不支持地理定位");
      }
    },
    //增加中文注记
    addZh() {
      this.map.removeLayer(this.lay_mongolian);
      this.map.addLayer(this.lay_zh);
      this.language = "zh";
      this.annotation();
    },
    //增加蒙古文注记
    addMongolian() {
      this.map.removeLayer(this.lay_zh);
      this.map.addLayer(this.lay_mongolian);
      this.language = "mo";
      console.log(this.map.getBounds, 1111);
      this.annotation();
    },
    // 标注
    async annotation(data) {
      let _this = this;
      console.log(_this.map.getZoom(), "::::::::::");
      if (_this.map.getZoom() >= 12) {
        let bs = _this.map.getBounds(); //获取可视区域
        let bssw = bs.getSouthWest(); //可视区域左下角
        let bsne = bs.getNorthEast(); //可视区域右上角
        console.log(bs)
        console.log(
          "当前地图可视范围是：" +
            bssw.getLng() +
            "," +
            bssw.getLat() +
            "到" +
            bsne.getLng() +
            "," +
            bsne.getLat()
        );
        const data = {
          mini:`${bssw.getLng()},${bssw.getLat()}`,
          max:`${bsne.getLng()},${bsne.getLat()}`
        }
        _this.fullscreenLoading = true
        const res = await getQuickSearchList(data)
        _this.fullscreenLoading = false
        console.log('getQuickSearchList::::', res)
        if (res.data && res.data.length) {
          _this.quickSearchList = res.data
        } else {
          return
        }
        // let arr = [];
        // this.quickSearchList.forEach((item) => {
        //   console.log(
        //     _this.isPointInsideRectangle(
        //       {lat:item.dimension,lon:item.longitude},
        //       {minLat:bssw.getLat(), maxLat:bsne.getLat(),minLon:bssw.getLng(), maxLon:bsne.getLng()}
        //     )
        //   );
        //   if (
        //     _this.isPointInsideRectangle(
        //       {lat:item.dimension,lon:item.longitude},
        //       {minLat:bssw.getLat(), maxLat:bsne.getLat(),minLon:bssw.getLng(), maxLon:bsne.getLng()}
        //     ) && item.type === '2'
        //   ) {
        //     arr.push(item);
        //   }
        // });
        // console.log("当前域定位", arr);
        console.log("当前域zoom", _this.map.getZoom());
        // let text =
        //   _this.language === "zh"
        //     ? "测试测试测试测试测试"
        //     : "ᠡᠵᠡᠨ᠎ᠡ ᠬᠣᠰᠢᠭᠤ ᠪᠦᠬᠦ ᠤᠯᠤᠰ ᠤᠨ";
        _this.map.clearOverLays()
        _this.quickSearchList.forEach((item, index) => {
          // if (item.type === '2') {
          // console.log(item)
            let marker = new T.Marker(new T.LngLat(item.longitude, item.dimension)); // 创建标注
            // console.log(_this.map.getOverlays()[0], marker)
            _this.map.addOverLay(marker); // 将标注添加到地图中
            let text =
            _this.language === "zh"
              ? item.name
              :  item.mnname;
            let label
            if (_this.language === 'zh') {
              label = new T.Label({
                text: "<b class='font'>" + text + "<b>",
                position: marker.getLngLat(),
                offset: new T.Point(Number(`-${((text.length*16)/2)+12}`), 20),
              });
            } else {
              label = new T.Label({
                text: "<b class='aqt-lang-mn font'>" + text + "<b>",
                position: marker.getLngLat(),
                offset: new T.Point(-22, 25),
              });
            }
            
            _this.map.addOverLay(label);
            marker.addEventListener("click", (e) => {
              // label.setLngLat(marker.getLngLat());
              _this.markerClick(item);
            });
          // }
        });
        console.log('yigx')
        if (_this.language === 'mo') {
           _this.unicode2M()
        }
        
      } else {
        _this.map.clearOverLays();
      }
    },
    async markerClick(item) {
      console.log(item.id);
      const data = { id: item.id }
      this.fullscreenLoading = true
      const res = await areafind(data)
      this.fullscreenLoading = false
      if (res.data) {
        if (this.language === 'zh') {
        // $('.el-dialog__title').append(`<span class="zh">${res.data.name} ${res.data.nameb} <span class="mo">${res.data.mnname} </span>${res.data.mnnameb}</span>`)
          this.contenttit = `<span class="zh">${res.data.name} ${res.data.nameb} <span class="mo">${res.data.mnname} </span>${res.data.mnnameb}</span>`
          this.contentData = `<div class="">${res.data.content}</div>`
        } else {
          this.contenttit = `<span class="mo">${res.data.mnname} <span class="zh"> ${res.data.mnnameb} ${res.data.name} ${res.data.nameb}</span></span>`
          this.contentData = `<div class="muesfont">${res.data.content}</div>`
        }
        this.dialogTableVisible = true;
        this.$nextTick(() => {
          $('.el-dialog__title').html(this.contenttit)
          _this.unicode2M()
        })
        // if (_this.language === 'mo') {
        //   _this.unicode2M()
        // }
      }
    },
    // 判断当前经纬度是否在地图经纬度范围内
    isPointInsideRectangle(point, range) {
      const { lat: pointLat, lon: pointLon } = point;
      const { minLat, maxLat, minLon, maxLon } = range;
    
      return (
        pointLat >= minLat &&
        pointLat <= maxLat &&
        pointLon >= minLon &&
        pointLon <= maxLon
      )
    },
    unicode2M() {
      this.$nextTick(() => {
          // console.log('aaaaaaaa',$('.aqt-lang-mn'))
          $('.tabBox button span').css('writing-mode',' vertical-rl')
          $('.el-dialog__header span').addClass('mo')
          $('.el-dialog__header span').each(function(){
              let dd = $(this).html()
              $(this).html(Unicode2M(dd))
          })
          $('.aqt-lang-mn').each(function(){
              let dd = $(this).html()
              $(this).html(Unicode2M(dd))
          })
          $('.muesfont').each(function(){
              let dd = $(this).html()
              $(this).html(Unicode2M(dd))
          })
          $('.inputdivsu dl dd').each(function(){
              let dd = $(this).html()
              $(this).html(Unicode2M(dd))
          })
          $('body input').each(function(){
              let dd = $(this).val()
              $(this).val(Unicode2M(dd))
          })
          $('bodys .mce-txt').each(function(){
              let dd = $(this).html()
              $(this).html(Unicode2M(dd))
          })
          $('bodys .mo').each(function(){
              let dd = $(this).html()
              $(this).html(Unicode2M(dd))
          })
        })
    },
    async areasearch(type) {
      if (type) {
        this.page = 1
      }
      this.inputVisible = true
      const data = {
        p: this.page,
        pageSize: '10',
        name: this.searchVal,
        sr_type: this.placeNameType
      }
      this.fullscreenLoading = true
      const res = await areasearch(data)
      this.fullscreenLoading = false
      console.log(res)
      if (res.data) {
        this.searchList = res.data
        this.total = Number(res.count)
      }
      // this.$nextTick(() => {
      //   const pagination__total = document.querySelector(
      //     ".el-pagination__total"
      //   )
      //   console.log(pagination__total,44444444)
      //   pagination__total.innerText = `共 ${this.total} 条结果`
      // })
    },
    searchPositioning(item) {
      // console.log(item.longaet)
      let arr = item.longaet.split(',').map(item => {
        return Number(item)
      })
      console.log(arr)
      this.map.centerAndZoom(new T.LngLat(arr[0], arr[1]), 12);
      this.serachVisible = false
      this.inputVisible = false
      // this.map.centerAndZoom(new T.LngLat(111.73, 40.83), 12)
    },
    paging(page) {
      this.page = page
      this.areasearch()
    },
    popoverShowFun(e) {
      // console.log(e.target)
      // console.log($('.logo')[0])
      // console.log(e.target === $('.logo')[0])
      let searchBox = $('.searchBox')[0]
      let popover = $('.el-popover')[0]
      if (searchBox && !searchBox.contains(e.target) && popover && !popover.contains(e.target)) {
        this.inputVisible = false
      }
    },
    placeNameTypeChange(data) {
      this.placeNameType = data
      this.areasearch(1)
      // this.serachVisible = true
    },
    preventSwipeBack(event) {
      const touch = event.touches[0];
      const startX = touch.pageX;
      const startY = touch.pageY;
 
      // 添加事件监听，用于检测滑动方向
      document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
      document.addEventListener('touchend', this.handleTouchEnd);
 
      function handleTouchMove(event) {
        const touch = event.touches[0];
        // 检测滑动方向
        const direction = this.getSwipeDirection(startX, startY, touch.pageX, touch.pageY);
        if (direction === 'left') {
          event.preventDefault(); // 阻止默认的滑动行为
        }
      }
 
      function handleTouchEnd() {
        // 移除监听
        document.removeEventListener('touchmove', this.handleTouchMove);
        document.removeEventListener('touchend', this.handleTouchEnd);
      }
 
      // 获取滑动方向
      function getSwipeDirection(startX, startY, endX, endY) {
        const dx = endX - startX;
        const dy = endY - startY;
        if (Math.abs(dx) > Math.abs(dy) && dx > 0) {
          return 'right'; // 右滑
        } else if (Math.abs(dx) > Math.abs(dy) && dx < 0) {
          return 'left'; // 左滑
        } else {
          return null; // 未触发滑动
        }
      }
    },
    refPage() {
      window.location.reload();
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
@function Vw($px) {
  @return 100vw/750px * $px;
}
.map {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}
.butBox {
  position: fixed;
  right: 20px;
  top: 32px;
}
@media(max-width: 1024px) {
  .butBox {
    right: auto;
    top:auto;
    left: 20px;
    bottom: 30px;
  }
}
.positioning {
  position: fixed;
  right: 11px;
  bottom: 88px;
  width: 28px;
  height: 28px;
  background: url(../assets/img/position.png) 0 0 no-repeat;
  background-size: cover;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.logo{
  width: 60px;
  height: 60px;
  background: url(../assets/img/logo.png) 0 0 no-repeat;
  background-size: cover;
  position: fixed;
  left: 20px;
  top: 15px;
  cursor: pointer;
}
.searchBox {
  position: fixed;
  left: 90px;
  top: 13px;
  border-radius: 10px;
  overflow: hidden;
  .inputBox {
    width: 700px;
    height: 60px;
    background-color: #fff;
    position: relative;
    box-shadow: 1px 10px 10px #efefef;
    // border:1px solid  #efefef;
    .input{
      display: flex;
    }
    .inputItem{
      width: 600px;
      height: 60px;
      border: none;
      outline: none;
      padding: 0 20px;
      font-size: 14px;
    }
    .inputBtn{
      width: 100px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background-color: #007af8;
      color:#fff;
      // border:1px solid  #efefef;
      cursor: pointer;
    }
  }
}
.searchBox1{
  left: -201px;
  top: 300px;
  transform: rotate(90deg);
  .inputBox {
    width: 500px;
    height: 60px;
    background-color: #fff;
    position: relative;
    box-shadow: 1px 10px 10px #efefef;
    // border:1px solid  #efefef;
    .input{
      width: 500px;
      height: 60px;
      display: flex;
      // flex-direction: column;
    }
    .inputItem{
      width: 380px;
      line-height: 60px;
      height: 60px;
      border: none;
      outline: none;
      padding: 0 20px;
      font-size: 14px;
      font-family: 'OrhonChaganTig';
      // writing-mode: vertical-rl;
    }
    .inputItem::placeholder{
      position: relative;
      right: 0;
      font-family: 'OrhonChaganTig';
    }
    .inputBtn{
      width: 100px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background-color: #007af8;
      color:#fff;
      font-family: 'OrhonChaganTig';
      // border:1px solid  #efefef;
      cursor: pointer;
    }
  }
  // .inputBox {
  //   height: 500px;
  //   width: 60px;
  //   background-color: #fff;
  //   position: relative;
  //   box-shadow: 1px 10px 10px #efefef;
  //   border:1px solid  #efefef;
  //   .input{
  //     height: 500px;
  //     width: 60px;
  //     display: flex;
  //     flex-direction: column;
  //   }
  //   .inputItem{
  //     height: 380px;
  //     line-height: 380px;
  //     width: 60px;
  //     border: none;
  //     outline: none;
  //     padding: 20px 0;
  //     font-size: 16px;
  //     writing-mode: vertical-rl;
  //   }
  //   .inputItem::placeholder{
  //     position: relative;
  //     right: 0;
  //   }
  //   .inputBtn{
  //     height: 100px;
  //     width: 60px;
  //     line-height: 60px;
  //     text-align: center;
  //     background-color: #007af8;
  //     color:#fff;
  //     // border:1px solid  #efefef;
  //     cursor: pointer;
  //   }
  // }
}
@media(max-width: 768px) {
  .logo{
    width: 50px;
    height: 50px;
    background: url(../assets/img/logo.png) 0 0 no-repeat;
    background-size: cover;
    position: fixed;
    left: 15px;
    top: 15px;
    
  }
  .searchBox {
    position: fixed;
    left: Vw(140px);
    top: 15px;
    .inputBox {
      width: Vw(580px);
      height: 50px;
      background-color: #fff;
      position: relative;
      box-shadow: 1px 10px 10px #efefef;
      // border:1px solid  #efefef;
      .input{
        display: flex;
      }
      .inputItem{
        height: 50px;
        border: none;
        outline: none;
        width: Vw(440px);
        padding: 0 20px;
        font-size: Vw(28px);
      }
      .inputBtn{
        width: Vw(120px);
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #007af8;
        color:#fff;
        // border:1px solid  #efefef;
        cursor: pointer;
      }
    }
  }
  .searchBox1{
  //   left: Vw(-190px);
  // top: Vw(341px);
  left: -161px;
  top: 253px;
  transform: rotate(90deg);
  .inputBox {
    width: 400px;
    height: 50px;
    background-color: #fff;
    position: relative;
    box-shadow: 1px 10px 10px #efefef;
    // border:1px solid  #efefef;
    .input{
      width: 400px;
      height: 50px;
      display: flex;
      // flex-direction: column;
    }
    .inputItem{
      width: 300px;
      line-height: 50px;
      height: 50px;
      border: none;
      outline: none;
      padding: 0 20px;
      font-size: 16px;
      // writing-mode: vertical-rl;
    }
    .inputItem::placeholder{
      position: relative;
      right: 0;
    }
    .inputBtn{
      width: 100px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #007af8;
      color:#fff;
      // border:1px solid  #efefef;
      cursor: pointer;
    }
  }
    // left: 13px;
    // top: 70px;
    // .inputBox {
    //   height: Vw(700px);
    //   width: Vw(70px);
    //   background-color: #fff;
    //   position: relative;
    //   box-shadow: 1px 10px 10px #efefef;
    //   border:1px solid  #efefef;
    //   .input{
    //     height: Vw(700px);
    //     width: Vw(70px);
    //     display: flex;
    //     flex-direction: column;
    //   }
    //   .inputItem{
    //     width: Vw(70px);
    //     border: none;
    //     outline: none;
    //     height: Vw(550px);
    //     padding: 20px 0;
    //     font-size: 16px;
    //   }
    //   .inputItem::placeholder{
    //     position: relative;
    //     right: Vw(0px);
    //   }
    //   .inputBtn{
    //     height: Vw(150px);
    //     width: Vw(70px);
    //     line-height: Vw(70px);
    //     text-align: center;
    //     background-color: #007af8;
    //     color:#fff;
    //     // border:1px solid  #efefef;
    //     cursor: pointer;
    //   }
    // }
  }
  .positioning{
    bottom: 125px;
  }
}
</style>
<style lang="scss">
@function Vw($px) {
  @return 100vw/750px * $px;
}
.map .tdt-iconLayers-layersRow {
  position: absolute;
  right: 44px;
  top: -13px;
}
.map .font {
  /* writing-mode: vertical-rl; */
  background-color: rgba(0, 0, 0, 0);
  text-shadow: 1px 1px 0 #fff,
    /* 水平偏移、垂直偏移、模糊半径、阴影颜色 */ -1px -1px 0 #fff;
  font-size: 16px;
}
.map .tdt-label {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 0;
  line-height: normal;
}

.map .tdt-label,
.map .tdt-label0,
.map .tdt-label1 {
  border: none;
}
.map .tdt-control-copyright {
    margin: 0;
    margin-bottom: 4px !important;
}
.searchListBox{
  border: none;
  outline: none;
  text-align: center;
  .tabBox{
    border: none;
    outline: none;
    display: flex;
    justify-content:space-around;
    padding: 10px;
    border-bottom: 1px solid #efefef;
    // writing-mode: vertical-rl;
    .line{
      width: 1px;
      height: 20px;
      background-color: #d0cfcf;
      display: inline-block;
    }
  }
  .searchList {
    border: none;
    outline: none;
    text-align: left;
    max-height: 40vh;
    min-height: 200px;
    .searchListItem {
      padding: Vw(8px) Vw(5px);
      cursor: pointer;
      position: relative;
      padding-left: 15px;
      background: url(../assets/img/bg3.png) 0 center no-repeat;
    }
    .searchListItem:hover {
      background-color: #dddddd;
    }
  }
  .paginationBox{
      display: flex;
      justify-content: center;
      .total{
         color:#a70000;
         line-height: 35px;
          font-size: 14px;
      }
    }
}
.searchListBox1{
  padding-left: Vw(50px);
  position: relative;
  .tabBox{
    writing-mode: vertical-rl;
    position: absolute;
    left: 0;
    top: Vw(-20px);
    height: 100%;
    display: flex;
    justify-content:space-around;
    align-items: center;
    padding: Vw(10px);
    border-right: 1px solid #efefef;
    button{
      width:40px;
      height:auto;
      margin-left: 0;
      font-size: 16px;
    }
    .line{
      width: 30px;
      height: 1px;
      background-color: #d0cfcf;
      display: inline-block;
      position: relative;
      left: -2px;
    }
  }
  text-align: center;
  height: 450px;
  background-color: #fff;
  .searchList {
    width:  100%;
    height: 400px;
    text-align: left;
    display: flex;
    max-height:none;
    margin-top: Vw(20px);
    // background-color: #f6f6f6;
    .searchListItem {
      display: inline-block;
      height: 400px;
      padding: Vw(8px) Vw(5px);
      box-sizing: border-box;
      cursor: pointer;
      padding-top: 24px;
      background: url(../assets/img/bg4.png) center 0 no-repeat;
    }
    .searchListItem:hover {
      background-color: #dddddd;
    }
  }
  .paginationBox{
      display: flex;
      justify-content: center;
      .total{
         color:#a70000;
         line-height: 35px;
          font-size: 14px;
          font-weight: 600;
      }
    }
}
@media(min-width: 768px) and (max-width: 1024px) {
  .searchListBox1{
    height: Vw(430px);
    .searchList{
    height: Vw(410px);
      
    }
  }
}
@media(max-width: 768px) {
  .searchListBox{
    .tabBox{
      display: flex;
      justify-content:space-around;
      padding: 10px 0;
    }
    text-align: center;
    .searchList {
      text-align: left;
      max-height: 40vh;
      .searchListItem {
        padding: Vw(8px) Vw(5px);
        cursor: pointer;
        padding-left: Vw(30px);
      }
      .searchListItem:hover {
        background-color: #dddddd;
      }
    }
    .paginationBox{
      display: flex;
      justify-content: center;
      .total{
         color:#a70000;
         line-height: 35px;
          font-size: 14px;
          font-weight: 600;
      }
    }
  }
  .searchListBox1{
    padding-left: Vw(70px);
    text-align: center;
    height: Vw(550px);
    background-color: #fff;
    justify-content: center;
    .tabBox{
      position: absolute;
      left: 0;
      top: Vw(-20px);
      height: 100%;
      display: flex;
      justify-content:space-around;
      padding: Vw(10px);
      border-right: 1px solid #efefef;
      background-color: #fff;
      button{
        width:Vw(35px);
        height:auto;
        margin-left: 0;
        font-size: Vw(22px);
      }
    }
    .searchList {
      width:  100%;
      height: Vw(480px);
      text-align: left;
      display: flex;
      max-height:none;
      .searchListItem {
        display: inline-block;
        height: Vw(480px);
        padding: Vw(8px) Vw(5px);
        box-sizing: border-box;
        cursor: pointer;
        padding-top: Vw(34px);
      }
      .searchListItem:hover {
        background-color: #dddddd;
      }
    }
    .paginationBox{
      display: flex;
      justify-content: center;
      .total{
        color:#a70000;
         line-height: 35px;
          font-size: 14px;
          font-weight: 600;
      }
    }
  }
  .map .tdt-iconLayers-layersRow {
    top: 23px;
  }
  .map .tdt-bottom .tdt-control {
    margin-bottom: 45px;
  }
}
.l50{
  left: -48px !important;
}
.mo{
  font-family: 'OrhonChaganTig';
}
.zh{
  font-family: 'FangSong';
}
.content{
  line-height: 1.6;
  max-height: 65vh;
  overflow-y: auto;
  padding: 0 8px;
}
.el-dialog{
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  background: url(../assets/img/bg2.jpg);
  .el-dialog__body{
    .line{
      width: 100%;
      height: 3px;
      background: url(../assets/img/bg1.jpg);
      background-size: cover;
      margin-bottom: 10px;
    }
    padding-top: 0;
  }
}
// .el-dialog__body{
//   padding-top: 0;
// }
</style>
